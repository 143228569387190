/* eslint-disable */
import { useEffect } from 'react';
import { ws_response } from './ws/ws_response';
import { useDispatch } from 'react-redux';

const useWebSocket = (url, functions) => {
  const Dispatch = useDispatch();
  
  useEffect(() => {
      function connectWebSocket() {
          const socket = new WebSocket(url);
          functions(socket);
          // Set up event handlers
          socket.onmessage = function (evt) {
              Dispatch(ws_response({evt:evt, ws:socket }));
          };
          socket.onclose = (event) => {
              console.log("websocket close")
              // Reconnect after a delay
              setTimeout(() => {
                  console.log("Reconnecting...");
                  connectWebSocket();
              }, 1000); // Adjust the delay as needed
          };
          return () => {
            socket.close();
          };
      };
      
      // Initial connection
      connectWebSocket();
  }, [url]);
};

export default useWebSocket;